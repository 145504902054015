button, .btn{
	&.languageSwitcher, &.chapter{
		background: $background-book;
		border: none;
		margin: 1rem;
		border-radius: 0;
		color: black;

		&.book{
			min-width: 130px;
			min-height: 180px;
			font-size: 1.2rem;
			font-weight: bold;
		}
	}
}

ul{
	&.download-links{
		text-align: left;
		list-style: none;
		padding: 0;
		margin: 0;

		a{
			display: inline-block;
			padding: 0.8rem 1rem;
		}
	}
}