.Home{
	width: 100%;

	p,
	h1, h2, h3,
	h4, h5, h6{
		text-align: center;
	}

	.languageButtons{
		text-align: center;

		button {
			position: relative;
			margin: 0 20px;

			img{
				position: absolute;
				right: 5px;
				bottom: 5px;
				width: 40px;
				height: auto;
			}
		}
	}
}