@import '../../_variables.scss';

.TimelineModal{
	position: relative;
	color: black;

	.modal-content{
		background: $background-book url('../../images/chapter_bg.jpg') repeat;
		border-radius: 0;

		box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.50);
		-webkit-box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.50);
		-moz-box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.50);

		.modal-header{
			position: absolute;
			z-index: 1;
			top: 0;
			right: 0;
			width: 100%;
			height: auto;
			padding-right: 30px;
			padding-top: 30px;
			border: none;
		}

		.modal-body{
			background: $background-paper url('../../images/modal_paper_bg.jpg') repeat;
			margin: 15px;

			box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.20);
			-webkit-box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.20);
			-moz-box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.20);
		}

		h1, h2{
			font-size: 1.2rem;
		}

		p{
			font-family: "Tinos", serif;
		}

		.modal-border-top-left{
			position: absolute;
			left: -10px;
			top: -16px;
			width: 237px;
			height: 44px;
			background: transparent url('../../images/modal_border_top_left.png') no-repeat;
		}

		.modal-border-top-right{
			position: absolute;
			right: -7px;
			top: -16px;
			width: 234px;
			height: 48px;
			background: transparent url('../../images/modal_border_top_right.png') no-repeat;
		}

		.modal-border-bottom-left{
			position: absolute;
			left: -6px;
			bottom: -18px;
			width: 268px;
			height: 42px;
			background: transparent url('../../images/modal_border_bottom_left.png') no-repeat;
		}

		.modal-border-bottom-right{
			position: absolute;
			right: -8px;
			bottom: -12px;
			width: 247px;
			height: 60px;
			background: transparent url('../../images/modal_border_bottom_right.png') no-repeat;
		}
	}
}