.SourceHeader{
	h2{
		font-size: 1.2rem;
		color: black;
		font-family: 'Tinos', serif;
		margin-bottom: 30px;
	}

	.chapter-name{
		font-style: italic;
	}
}