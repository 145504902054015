.TestView{
	display: flex;
	flex-direction: column;
	min-height: 100vh;

	.TestHeader{
		background-color: red;
		width: 100%;
	}

	.TestChapter{
		flex-grow: 1;
		overflow: hidden;
		display: flex;
		flex-direction: column;

		.TestPage{
			background-color: green;
			flex-grow: 1;
		}
		.TestPageList{
			background-color: blue;
			flex-grow: 1;
		}
	}

	.TestFooter{
		background-color: orange;
		width: 100%;
	}

	.scrollable-content{
		flex-grow: 1;
		height: calc(100vh - var(--header-height) - var(--footer-height));
		overflow-y: auto;
	}
}