@import '../../_variables.scss';

.ChapterButton{
	position: relative;
	background: $background-paper url('../../images/chapter_bg.jpg') repeat;
	width: 100%;
	max-width: 130px;
	min-height: 200px;
	font-size: 1rem;
	border: none;
	margin: 0;
	padding: 6px 10px;
	text-decoration: none;
	color: black;
	border-radius: 0;

	&:hover, &:active{
		color: black;
	}

	.title {
		position: relative;	
		z-index: 10;
	}

	.page-fold{
      position: absolute;
      z-index: 0;
      left: 0;
      top: 0;
      background: transparent url('../../images/chapter_btn_bg_fold_middle.png') repeat-y;
      width: 30px;
      height: 100%;
    }

    .page-fold-top{
      position: absolute;
      z-index: 0;
      left: 0;
      top: 0;
      background: transparent url('../../images/chapter_btn_bg_center_top.png') no-repeat;
      width: 120px;
      height: 44px;
    }

    .page-fold-bottom{
      position: absolute;
      z-index: 0;
      left: 0;
      bottom: 0;
      background: transparent url('../../images/chapter_btn_bg_center_bottom.png') no-repeat;
      width: 120px;
      height: 47px;
    }
}