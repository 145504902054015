@import '../../_variables.scss';

.Chapters{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: stretch;
    gap: 40px;

    .links{
    	gap: 20px;
    }
}