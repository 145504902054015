@import './_variables.scss';

.App {
  //display: flex;
  //text-align: center;
  max-width: 1200px;
  margin: 0 auto;
  //height: 100vh;
  //justify-content: center; /*centers items on the line (the x-axis by default)*/
  //align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: center;

  .Header{
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 40px;

    img{
      height: 120px;
      width: auto;
      border-radius: 2px;
    }
  }

  .main-content{
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }

  .Footer{
    width: 100%;
    padding: 20px;
    border-top: 1px solid #9c9677;

    .LearningResources{
      ul{
        list-style: none;
        display: flex;
        color: white;
        grid-gap: 1rem;
        align-items: center;
        justify-content: center;

        a{
          color: white;
          text-decoration: underline;

          &:hover{
            color: grey;
          }
        }
      }
    }

    #partner-logos{
      padding: 20px;
      text-align: center;
      font-size: 0.8rem;
      font-family: "Tinos", serif;

      img {
        margin: 10px 30px;
        width: 120px;
        height: auto;
      }
    }
  }


  a {
    text-decoration: none;
    color: black;

    &:hover, &:active{
      color: black;
    }
  }

  button{
    cursor: pointer;
    -webkit-transition: color 0.2s, font-size 0.2s;
    -moz-transition: color 0.2s, font-size 0.2s;
    -o-transition: color 0.2s, font-size 0.2s;
    transition: color 0.2s, font-size 0.2s;

    &:hover{
      font-size: 1.1rem;
    }
  }

  .Chapter{
    display: flex;
    flex-direction: column;
    height: 100vh;

    .ChapterContent{
      flex-grow: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      .Pages{
        flex-grow: 1;
      }

      .Sidebar{
        flex-grow: 1;
      }

      .scrollable-content{
        flex-grow: 1;
        height: calc(100vh - var(--header-height) - var(--footer-height));
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;

        .thumb {
          width: 50px;
          background-image: white url('images/scollbar.png');
          background-position: center bottom, center -40px;
          background-repeat: no-repeat, no-repeat;
          background-size: contain, contain;
        }
      }
    }

    .ChapterContent{
      .Page{
        position: relative;
        background: $background-book url('images/chapter_bg.jpg') repeat;
        padding: 2rem;

        * {
          z-index: 1;
        }

        p, ul, li,
        h1, h2, h3
        h4, h5, h6{
          color: black;
        }

        img, video {
          box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.50);
          -webkit-box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.50);
          -moz-box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.50);
        }

        .page-fold{
          position: absolute;
          z-index: 0;
          left: 50%;
          top: 0;
          transform: translateX(-50%);
          background: transparent url('images/chapter_bg_fold_middle_w120px.png') repeat-y;
          width: 120px;
          height: 100%;
        }

        .page-fold-top{
          position: absolute;
          z-index: 0;
          left: 50%;
          top: 0;
          transform: translateX(-50%);
          background: transparent url('images/chapter_bg_center_top.png') no-repeat;
          width: 120px;
          height: 87px;
        }

        .page-fold-bottom{
          position: absolute;
          z-index: 0;
          left: 50%;
          bottom: 0;
          transform: translateX(-50%);
          background: transparent url('images/chapter_bg_center_bottom.png') no-repeat;
          width: 120px;
          height: 94px;
        }

        .SheetOfPaper{
          position: relative;
          background: $background-paper url('images/paper_bg.jpg') repeat;
          padding: 30px 30px 30px 50px;
          max-height: 100%;

          box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.50);
          -webkit-box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.50);
          -moz-box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.50);

          border: 1px solid #9c9677;

          * {
            z-index: 1;
          }

          .paper-border-top{
            z-index: 0;
            height: 15px;
            width: 100%;
            position: absolute;
            left: 0;
            top: 70px;
            background: transparent url('images/paper_top_border_h15px.png') repeat-x;
          }

          .paper-border-left{
            z-index: 0;
            height: 100%;
            width: 15px;
            position: absolute;
            left: 25px;
            top: 0;
            background: transparent url('images/paper_left_border_w15px.png') repeat-y;
          }

          .transcript{
            font-style: italic;
          }

          p, ul, ol, li{
            font-family: 'Tinos', serif;
          }
        }
      }

      .Sidebar{
        .Pagelist{
          position: relative;
          background:  $background-book url('images/chapter_bg.jpg') repeat;
          //height: 70%;
          overflow-y: scroll;

          .page-list-border{
            position: absolute;
            z-index: 0;
            left: 0;
            top: 0;
            height: 100%;
            width: 35px;
            background: $background-book url('images/pagelist_left_border_bg_w35px.png') repeat-y;
          }

          ul{
            position: relative;
            z-index: 10;
            text-align: right;
            list-style: none;
            margin: 0;
            padding: 0;

            li{
              background: transparent url('images/pagelist_page_border_w78px.png') no-repeat;
              
              a{
                padding: 0.5rem 10px 0.5rem 40px;
                display: block;
                color: black;
                text-decoration: none;
                border-top: 1px solid #8d6532;
                text-align: right;

                &:hover, &.selected{
                  background-color: rgba(129, 87, 52, 0.4);
                }
              }
            }
          }
        }

        .backlink{
          height: auto;
          text-align: right;
          display: block;
          color: white;
          text-decoration: none;
          font-size: 1.2rem;
          margin-bottom: 1rem;
        }

        .LanguageSwitch{
          height: auto;
          margin-top: 1rem;

          h4{
            font-size: 1rem;
          }

          button{
            &.languageSwitcher{
              margin: 0.2rem;

              &:hover{
                font-size: 1rem;
                text-decoration: underline;
              }
            }
          }

          /*button{
            &.btn.languageSwitcher{
              font-size: 0.8rem;
              margin: 0.2em 0.3em;
            }
          }*/
        }

        .PageList{
          flex: 1;
          height: unset;
        }
      }
    }
  }
  .Timeline{
    height: 100px;

    ul{
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
      font-size: 1.5rem;
    }

    #portrait-josef-grabowski{
      width: 120px;
      height: auto;
    }

    .year{
      &.current{
        background-image: url('images/timeline_selected_year.png');
        background-position: center; /* Center the image */
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: contain;
      }
    }

    a {
      display: flex;
      min-width: 50px;
      height: auto;
      color: white;
      text-decoration: none;
      vertical-align: middle;
      aspect-ratio: 1 / 1;
      align-items: center;
      padding: 1rem;

      &:hover{
        background-image: url('images/timeline_gradient2.png');
        background-position: center; /* Center the image */
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: contain;
      }
    }
  }
}

/*
.App, .Chapter, .ChapterContent{
  height: 50vh;
}

.Page, .Pages, .Sidebar{
  max-height: 100%;
  overflow: hidden;
}

.Page, .Pagelist{
  overflow: scroll;
}
*/

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body, p, button,
h1, h2, h3,
h4, h5, h6{
  font-family: 'Special Elite', cursive;
  text-align: left;
}

video{
  width: 100%;
  height: auto;
  object-fit: cover;
}

img{
  max-width: 100%;
}

table{
  width: 100%;
}

ul, ol, li{
  text-align: left;
}

@import './components/components.scss';